import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import { getCountries, submitApplication, getCompanyStyles } from "../services/api";
import { FaLinkedin } from "react-icons/fa";
import "../styles/ApplicationForm.css";
import { subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader";

const ApplicationForm = ({ company }) => {
    const [companyStyles, setCompanyStyles] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        setError: setFormError,
        clearErrors,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchCompanyStyles = async () => {
            setLoading(true);
            try {
                if (company) {
                    const styles = await getCompanyStyles(company);
                    setCompanyStyles(styles);
                }
            } catch (err) {
                setError("Company not found");
            } finally {
                setLoading(false);
            }
        };

        fetchCompanyStyles();
    }, [company]);

    useEffect(() => {
        const fetchCountries = async () => {
            const countriesData = await getCountries();
            setCountries(countriesData);
        };

        fetchCountries();
    }, []);

    // Применяем стили компании динамически
    useEffect(() => {
        if (companyStyles && companyStyles.styles) {
            const styleElement = document.createElement("style");
            styleElement.textContent = companyStyles.styles;
            document.head.appendChild(styleElement);

            return () => {
                document.head.removeChild(styleElement);
            };
        }
    }, [companyStyles]);

    const onSubmit = async (data) => {
        if (!data["photo"]) {
            setFormError("photo", { type: "required", message: "This field is required" });
        }
        if (!dateOfBirth) {
            setFormError("dateOfBirth", { type: "required", message: "This field is required" });
            return;
        }

        const invalidField = isFormInvalide(data);
        if (invalidField) {
            console.log("Invalid field:", invalidField);
            setFormError(invalidField, { type: "required", message: "This field is required" });
            return;
        }

        const formData = new FormData();

        const date = new Date(dateOfBirth);
        date.setMinutes(-dateOfBirth.getTimezoneOffset());
        const formattedDateOfBirth = date.toISOString();

        Object.keys(data).forEach((key) => {
            if (key === "countryOfResidency" || key === "nationality" || key === "phoneCode") {
                formData.append(key, data[key]?.value || "");
            } else if (key === "linkedInProfileUrl") {
                formData.append(key, `https://www.linkedin.com/in/${data[key]}`);
            } else if (key === "dateOfBirth") {
                formData.append(key, formattedDateOfBirth);
            } else {
                formData.append(key, data[key]);
            }
        });

        formData.append("companyId", company);

        if (selectedPhoto) {
            formData.append("photo", selectedPhoto);
        }

        try {
            await submitApplication(formData);
            reset(); // Clear form fields
            resetDropdowns();
            setSelectedPhoto(null);
            setDateOfBirth(null);
            navigate(`/${company}/application-success`);
        } catch (error) {
            console.error("Form submission error:", error);
            toast.error("An error occurred while submitting your application. Please try again later.");
        }
    };

    const isFormInvalide = (data) => {
        const requiredFields = [
            "name",
            "address",
            "countryOfResidency",
            "nationality",
            "email",
            "phoneCode",
            "phoneNumber",
            "linkedInProfileUrl",
            "willingToRelocate",
            "gender",
        ];

        for (const field of requiredFields) {
            if (!data[field]) {
                return field;
            }
        }

        return false;
    };

    const resetDropdowns = () => {
        // Reset all dropdowns to their initial state
        ["countryOfResidency", "nationality", "phoneCode"].forEach((fieldName) => {
            control._fields[fieldName]?._reset();
        });
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedPhoto(file);
        }
    };

    const handlePhotoDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedPhoto(file);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClearForm = () => {
        reset();
        setSelectedPhoto(null);
    };

    const minDate = subYears(new Date(), 65);
    const maxDate = subYears(new Date(), 18);

    const handlePhotoDropZoneClick = () => {
        fileInputRef.current.click();
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className={`application-form-container ${company || ""}`}>
            {companyStyles && companyStyles.logo && (
                <div className="company-logo-container">
                    <img src={companyStyles.logo} alt={`${company} logo`} className="company-logo" />
                </div>
            )}
            <div className={`application-form ${company || ""}`}>
                <header>
                    <h1>Application Form</h1>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label" htmlFor="name">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            {...register("name", {
                                required: true,
                                pattern: /^[A-Za-z\s]+$/,
                            })}
                            placeholder="Enter your full name"
                        />
                        {errors.name && <span className="error-message">{errors.name.message}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            id="address"
                            {...register("address", { required: true })}
                            placeholder="Enter your address"
                        />
                        {errors.address && <span className="error-message">{errors.address.message}</span>}
                    </div>

                    <div className="form-group">
                        <label className="react-select__label" htmlFor="countryOfResidency">
                            Country of Residency
                        </label>
                        <Controller
                            name="countryOfResidency"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    id="countryOfResidency"
                                    options={countries.map((country) => ({
                                        value: country.code,
                                        label: country.name,
                                        flag: country.flag,
                                    }))}
                                    formatOptionLabel={(country) => (
                                        <div className="country-option">
                                            <span className="country-flag">{country.flag}</span>
                                            <span>{country.label}</span>
                                        </div>
                                    )}
                                    placeholder="Select your residency"
                                    classNamePrefix="react-select"
                                />
                            )}
                        />
                        {errors.countryOfResidency && (
                            <span className="error-message">{errors.countryOfResidency.message}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="react-select__label" htmlFor="nationality">
                            Nationality
                        </label>
                        <Controller
                            name="nationality"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    id="nationality"
                                    options={countries.map((country) => ({
                                        value: country.code,
                                        label: country.name,
                                        flag: country.flag,
                                    }))}
                                    formatOptionLabel={(country) => (
                                        <div className="country-option">
                                            <span className="country-flag">{country.flag}</span>
                                            <span>{country.label}</span>
                                        </div>
                                    )}
                                    placeholder="Select your nationality"
                                    classNamePrefix="react-select"
                                />
                            )}
                        />
                        {errors.nationality && <span className="error-message">{errors.nationality.message}</span>}
                    </div>
                    <div className="form-group">
                        <label className="label" htmlFor="email">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            {...register("email", {
                                required: true,
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            })}
                            placeholder="Enter your email address"
                        />
                        {errors.email && <span className="error-message">{errors.email.message}</span>}
                    </div>

                    <div className="form-group phone-group react-wrapper">
                        <label className="label" htmlFor="phoneNumber">
                            Phone Number
                        </label>
                        <div className="phone-input-container">
                            <Controller
                                name="phoneCode"
                                control={control}
                                rules={{ required: true, pattern: /^\+[0-9]{1,4}$/ }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id="phoneCode"
                                        options={countries.map((country) => ({
                                            value: country.dial_code,
                                            label: country.name,
                                            flag: country.flag,
                                        }))}
                                        formatOptionLabel={(option) => (
                                            <div className="country-option">
                                                <span className="country-flag">{option.flag}</span>
                                                <span>{option.value}</span>
                                            </div>
                                        )}
                                        placeholder="Code"
                                        classNamePrefix="react-select_phone"
                                    />
                                )}
                            />

                            <input
                                type="tel"
                                id="phoneNumber"
                                {...register("phoneNumber", { required: true, pattern: /^[0-9]{7,15}$/ })}
                            />
                        </div>
                        {errors.phoneCode && <span className="error-message">{errors.phoneCode.message}</span>}
                        {errors.phoneNumber && <span className="error-message">{errors.phoneNumber.message}</span>}
                    </div>

                    <div className="form-group">
                        <label className="label" htmlFor="dateOfBirth">
                            Date of Birth
                        </label>
                        <DatePicker
                            id="dateOfBirth"
                            selected={dateOfBirth}
                            onChange={(date) => {
                                setDateOfBirth(date);
                                setValue("dateOfBirth", date); // Update the form value
                                clearErrors("dateOfBirth");
                            }}
                            customInput={
                                <InputMask
                                    mask="99/99/9999"
                                    maskChar={null}
                                    className="styled-masked-input"
                                    placeholder="mm/dd/yyyy"
                                />
                            }
                            minDate={minDate}
                            maxDate={maxDate}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={47}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            autoComplete="off"
                        />
                        {errors.dateOfBirth && <span className="error-message">{errors.dateOfBirth.message}</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="photo">Please upload your recent photo</label>
                        <div
                            className="photo-drop-zone"
                            onDrop={handlePhotoDrop}
                            onDragOver={handleDragOver}
                            onClick={handlePhotoDropZoneClick}
                        >
                            <input
                                type="file"
                                id="photo"
                                accept="image/jpeg,image/png"
                                style={{ display: "none" }}
                                {...register("photo", {
                                    required: true,
                                    onChange: (e) => handlePhotoChange(e),
                                })}
                                ref={(e) => {
                                    register("photo", { required: true }).ref(e);
                                    fileInputRef.current = e;
                                }}
                            />
                            <p>Drag and drop your photo here or click to select</p>
                            {selectedPhoto && (
                                <div className="photo-preview">
                                    <img src={URL.createObjectURL(selectedPhoto)} alt="Selected" />
                                </div>
                            )}
                            <button type="button" className="choose-file-button">
                                Choose File
                            </button>
                        </div>
                        {errors.photo && <span className="error-message">Please upload a photo</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="linkedInProfileUrl">LinkedIn Profile URL</label>
                        <div className="linkedin-input">
                            <span className="linkedin-prefix">
                                <FaLinkedin />
                                https://www.linkedin.com/in/
                            </span>
                            <input
                                type="text"
                                id="linkedInProfileUrl"
                                {...register("linkedInProfileUrl", {
                                    required: true,
                                    pattern: /^[a-zA-Z0-9_-]{2,30}$/,
                                })}
                            />
                            {errors.linkedInProfileUrl && (
                                <span className="error-message">{errors.linkedInProfileUrl.message}</span>
                            )}
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="radio-label">Are you willing to relocate?</label>
                        <div className="radio-group react-wrapper">
                            <label>
                                <input
                                    type="radio"
                                    value="Yes"
                                    {...register("willingToRelocate", { required: true })}
                                />
                                Yes
                            </label>
                            <label>
                                <input type="radio" value="No" {...register("willingToRelocate", { required: true })} />
                                No
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Can consider"
                                    {...register("willingToRelocate", { required: true })}
                                />
                                Can consider
                            </label>
                        </div>
                        {errors.willingToRelocate && (
                            <span className="error-message">{errors.willingToRelocate.message}</span>
                        )}
                    </div>
                    <div className="form-group ">
                        <label className="radio-label">Gender</label>
                        <div className="radio-group react-wrapper">
                            <label>
                                <input type="radio" value="Male" {...register("gender", { required: true })} />
                                Male
                            </label>
                            <label>
                                <input type="radio" value="Female" {...register("gender", { required: true })} />
                                Female
                            </label>
                            <label>
                                <input type="radio" value="Other" {...register("gender", { required: true })} />
                                Other
                            </label>
                        </div>
                        {errors.gender && <span className="error-message">{errors.gender.message}</span>}
                    </div>

                    <div className="form-actions">
                        <button type="submit">Submit Application</button>
                        <button type="button" onClick={handleClearForm}>
                            Clear Form
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ApplicationForm;
